/* mixin */

@mixin cf {
	&:after,
	&:before {
		content: '';
		display: table;
	}
	&:after {
		clear: both;
	}
	& {
		zoom: 1;
	}
}

@for $i from 0 through 20 {
	.mgtop#{$i * 5} {
		margin-top:#{$i * 5}px;
	}
	.pdtop#{$i * 5} {
		padding-top:#{$i * 5}px;
	}
}

/* layout */

#main {
	padding-top: 80px;
	@media #{$mobile} {
		padding-top: 56px;
	}
}

.block {
	padding: 100px 3em;
	width: 100%;
	&.no_side {
		padding-left: 0;
		padding-right: 0;
	}
	&.no_btm {
		padding-bottom: 0;
	}
	@media #{$mobile} {
		padding: 50px 1em 100px;
	}

}

.inr_block {
	max-width: 980px;
	width: 100%;
	margin: 0 auto;
	padding: 0 1em;
	&.small {
		max-width: 740px;
	}
}

.box {
	margin-bottom: 3em;
}

.bg {
	&_sub {
		background: $c-main;
	}
}

.hover {
	transition: .3s;
	&:hover {
		opacity: .7;
	}
}

iframe {
	max-width: 100%;
}


* {
	
}

.effect {
	opacity: 0;
	transition: .8s;
	transform: translate(0,20px); 
}
.effect_on {
	opacity: 1.0;
	transform: translate(0,0); 
}

/* タイトル */

.tit {
	&.primary {
		text-align: center;
		font-size: 3.4rem;
		font-weight: bold;
		letter-spacing: .3em;
		margin-bottom: .3em;
	}
	&.line {
		text-align: center;
		margin-bottom: 1em;
		span {
			font-weight: bold;
			font-size: 2.4rem;
			background: linear-gradient(transparent 50%, #10cea2 0%);
			letter-spacing: .2em;
		}
	}
	&_sub {
		font-weight: bold;
		font-size: 2.4rem;
		letter-spacing: .2em;
		.en {
			margin-left: 1em;
			display: inline-block;
		}
	}
	@media #{$mobile} {
		&.primary {
			font-size: 2.2rem;
		}
		&.line {
			span {
				font-size: 1.8rem;
			}
		}
		&_sub {
			font-size: 1.8rem;
		}
	}
}


/* テーブル */

.table_base {
	border-collapse: collapse;
	border-top: 1px solid $c-main;
	border-bottom: 1px solid $c-main;
	width: 100%;
	th {
		width: 30%;
		padding: 10px;
		font-weight: bold;
		vertical-align: top;
	}
	td {
		padding: 10px;
		vertical-align: top;
	}
	tr:nth-child(odd) {
		th,
		td {
			background: $c-main;
			color: #000;
		}
	}
	@media #{$mobile} {
		th,
		td {
			display: block;
			width: 100%;
		}
		th {
			padding-bottom: 0;
		}
		td {
			padding-top: 0;
		}
	}
}


/* ボタン */

.btn {
	text-align: center;
	margin-top: 3em;
	a {
		display: inline-block;
		margin: 0 uto;
		border: 2px solid $c-yellow;
		text-decoration: none;
		padding: 1em 2em;
		font-weight: bold;
		letter-spacing: .2em;
		position: relative;
		overflow: hidden;
		transition: .3s;
		color: $c-yellow;
		&:after {
			position: absolute;
			transition: .3s;
			content: '';
			width: 0;
			left: 50%;
			bottom: 0;
			height: 3px;
			background: $c-yellow;
		}
		&:hover {
			box-shadow: inset 0px 0px 0px 3px $c-yellow;
			&:after {
				width: 0%;
			}
		}
	}
}


/* モジュール */

.border_tryangle {
	padding-top: 160px;
	position: relative;
	&:before {
		content: "";
		background: url(../img/back_image_texture.png) repeat-x center;
		display: block;
		height: 123px;
		width: 100%;
		position: absolute;
		top: -56px;
		left: 0;
	}
	&.mt {
		margin-top: 100px;
		padding-top: 60px;
	}
	@media #{$mobile} {
		padding-top: 80px;
		&:before {
			height: 60px;
			top: -30px;
			background-size: cover;
		}
	}
}

.center {
	text-align: center;
}


/* about 
****************************/

.client_area {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	dl {
		width: 50%;
		margin-bottom: 2em;
	}
	@media #{$mobile} {
		dl {
			width: 100%;
		}
	}
}


/* recruit 
****************************/

.row {
	width: 100%;
	padding: 3em 1em;
	background: $c-sub;
	&:nth-child(2n) {
		background: $c-sub-dark;
	}
	.inr_block {
		display: flex;
		justify-content: space-between;
		.img_box {
			width: 30%;
		}
		.txt_box {
			width: 65%;
		}
		.staff_sub {
			font-size: 2rem;
			font-weight: bold;
		}
		.staff_txt {
			margin-top: 1em;
		}
	}
	@media #{$phone} {
		.inr_block {
			display: block;
			.tit_sub {
				text-align: center;
				.en {
					margin: 0;
					display: block;
					font-size: 1rem;
				}
			}
			.img_box{
				width: 100%;
				text-align: center;
				max-width: 60%;
				margin: 0 auto 1em;
			}
			.txt_box {
				width: 100%;
			}
			.staff_sub {
				font-size: 1.4rem;
				text-align: center;
			}
		}
	}
}

.manga li {
	margin-top: 1em;
}

.ico_check {
	margin-bottom: .5em;
	&:before {
		font-family: "FontAwesome";
		content: "\f046";
		padding-right: .3em;
		color: $c-yellow;
	}
}



/* form */
.form_area {
	input:not([type=checkbox]), button, textarea, select {
//		margin: 0;
//		padding: 0;
		background: none;
		border: none;
		border-radius: 0;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		transition: all .3s;
		
		background: #fff;
		padding: 10px;
	}
	
	input[type="text"],
	input[type="email"],
	textarea {
		width: 100%;
	}
	
	input[type="text"]:focus,
	textarea:focus {
	}
	input + label {
		margin-right: 1em!important;
	}
	.btn {
		text-align: center;
		margin-top: 3em;
		button {
			display: inline-block;
			text-shadow: none;
			margin: 0 uto;
			border: 2px solid $c-yellow;
			text-decoration: none;
			padding: 1em 2em;
			font-weight: bold;
			letter-spacing: .2em;
			position: relative;
			overflow: hidden;
			transition: .3s;
			color: $c-yellow;
			background: none;
			&:after {
				position: absolute;
				transition: .3s;
				content: '';
				width: 0;
				left: 50%;
				bottom: 0;
				height: 3px;
				background: $c-yellow;
			}
			&:hover {
				box-shadow: inset 0px 0px 0px 3px $c-yellow;
				&:after {
					width: 0%;
				}
			}
		}
	}
}

#mfp_overlay_inner {
	color: #333;
}
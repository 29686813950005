/* footer */

footer {
	background: $c-main-dark;
	color: white;
	text-align: center;
	padding: 50px;
	@media #{$mobile} {
		padding: 1em;
	}
}
.copylight {
	margin: 3em 0 0;
}
/* header */

#top_head {
	top: -100px;
	position: absolute;
	width: 100%;
	margin: 100px auto 0;
	padding: 10px 0 15px;
	line-height: 1;
	z-index: 999;
	background: $c-main-dark;
	color: #fff;
	text-decoration: none;
	a {
		color: #fff;
		text-decoration: none;
	}
	.inr_block {
		position: relative;
		height: 80px;
		&:after {
			content: "";
			clear: both;
			display: block;
		}
	}
	.logo {
		position: absolute;
		left: 0;
		bottom: 0;
	}
}

#global_nav ul {
	list-style: none;
	position: absolute;
	right: 0;
	bottom: 10px;
	font-size: 1.6rem;
	display: flex;
	align-items: baseline ;
	li {
		
		position: relative;
		margin: 0 20px;
		a {}
		&.contact {
			background:#45b5a1;
			padding: .5em .7em;
			transition: .3s;
			&:after,
			&.active:after {
				content: none;
			}
			&:hover {
				opacity: .7;
			}
		}
		
		&:after,
		&.active:after {
			position: absolute;
			content: "";
			display: block;
			width: 100%;
			height: 2px;
			bottom: -5px;
			transition: all 0.3s ease;
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
		}
		&:hover:after,
		&.active:after {
			background: #fff;
			bottom: -10px;
		}
	}
}
//header {
//	padding: 20px 0 10px;
//	.inr_block {
//		display: flex;
//		align-items: flex-end;
//		justify-content: flex-end;
//		#logo {
//			margin-right: auto;
//		}
//	}
//	.nav_main {
//		li {
//			display: inline-block;
//			margin-left: 1.6em;
//			font-size: 1.8rem;
//			a {
//				text-decoration: none;
//			}
//			&.active {
//				border-bottom: 2px solid #4dcdb6;
//			}
//			&.contact {
//				background:#45b5a1;
//				padding: .1em .7em;
//			}
//		}
//	}
//}

#top_head.fixed {
	margin-top: 0;
	top: 0;
	position: fixed;
	background: #fff;
	background: rgba(31, 31, 31, 0.7);
	transition: top 0.65s ease-in;
	-webkit-transition: top 0.65s ease-in;
	-moz-transition: top 0.65s ease-in;
	.inr_block {
		height: 60px;
	}
	.logo {
		width: 200px;
	}
	#global_nav ul li a {}
}


/* Toggle Button */

#nav_toggle {
	display: none;
	position: absolute;
	right: 12px;
	top: 14px;
	width: 34px;
	height: 36px;
	cursor: pointer;
	z-index: 101;
	div {
		position: relative;
	}
	span {
		display: block;
		position: absolute;
		height: 4px;
		width: 100%;
		background: #fff;
		left: 0;
		transition: .35s ease-in-out;
		-webkit-transition: .35s ease-in-out;
		-moz-transition: .35s ease-in-out;
		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(2) {
			top: 11px;
		}
		&:nth-child(3) {
			top: 22px;
		}
	}
}

@media #{$mobile} {
	#top_head,
	.inr_block {
		width: 100%;
		padding: 0;
		height: auto;
	}
	#top_head {
		top: 0;
		position: fixed;
		margin-top: 0;
		.inr_block {
			height: auto;
		}
		&.fixed {
			padding-top: 0;
			.inr_block {
				height: auto;
			}
		}
	}
	/* Fixed reset */
	#mobile_head {
		background: rgba(31, 31, 31, 0.7);
		width: 100%;
		height: 56px;
		z-index: 999;
		position: relative;
	}
	#top_head {
		&.fixed .logo,
		.logo {
			position: absolute;
			left: 13px;
			top: 10px;
			color: #333;
			width: 140px;
		}
	}
	#global_nav {
		position: absolute;
		top: -500px;
		background: #333;
		width: 100%;
		text-align: center;
		padding: 10px 0;
		-webkit-transition: .5s ease-in-out;
		-moz-transition: .5s ease-in-out;
		transition: .5s ease-in-out;
		padding: 5px 0 0;
		ul {
			list-style: none;
			position: static;
			right: 0;
			bottom: 0;
			flex-flow: column;

			li {
				position: static;
				margin: 0;
				width: 100%;
				&:after,
				&.active:after {
					content: none;
				}
				&:hover:after {
					background: #0062B8;
					bottom: -20px;
				}
			}
		}
	}
	#top_head {
		#global_nav ul li a,
		&.fixed #global_nav ul li a {
			width: 100%;
			display: block;
			color: #fff;
			padding: 18px 0;
		}
	}
	#nav_toggle {
		display: block;
	}
	.open {
		#nav_toggle span {
			&:nth-child(1) {
				top: 11px;
				transform: rotate(315deg);
			}
			&:nth-child(2) {
				width: 0;
				left: 50%;
			}
			&:nth-child(3) {
				top: 11px;
				transform: rotate(-315deg);
			}
		}
		#global_nav {
			transform: translateY(556px);
		}
	}
}
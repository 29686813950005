@charset "UTF-8";

* {
	box-sizing: border-box;
}
a:focus, *:focus { outline:none; }
a { color: #fff; }
html {
	width: 100%;
	height: 100%;
	font-size: 62.5%;
	@media #{$mobile} {
		min-width: 100%;
	}
}

body {
	width: 100%;
	cursor: auto;
	font-family: 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', Arial, verdana, sans-serif;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: normal;
	line-height: 1.7;
	-webkit-text-size-adjust: 100%;
	color: #fff;
	background: $c-main-dark;
	@media #{$mobile} {
		font-size: 1.4rem;
	}
}

input,textarea {
	outline: none;
}

input {
	transition: 0.15s;
}

strong {
	font-weight: bold;
}

img {
	max-width: 100%;
	border: none;
	height: auto;
}


table {
	border-collapse: collapse;
}
.clearfix:after {
	content: "";
	clear: both;
	display: table;
}
.clearfix + * {
	clear: both;
}


.sp_mode {
	display: none;
	@media #{$mobile} {
		display: block;
	}
}
.pc_mode {
	display: block;
	@media #{$mobile} {
		display: none;
	}
}
/* top */

.mainvisual_wrap {
	background: #fff;
	position: relative;
	.main_txt {
		position: absolute;
		background: rgba(69, 181, 161, .75);
		right: 0;
		top: 50%;
		transform: translateY(-30%);
		width: 50%;
		font-weight: bold;
		padding: .5em 1em .5em 3em;
		min-width: 590px;
		text-align: left;
		h1 {
			font-size: 4.8rem;
		}
		p {
			font-size: 2.4rem;
		}
	}
	@media #{$mobile} {
		.main_txt {
			position: static;
			transform:  none;
			width: 100%;
			min-width: auto;
			text-align: center;
			padding: 1em;
			h1 {
				font-size: 3rem;
			}
			p {
				font-size: 1.6rem;
			}
		}
	}
}

.mainvisual {
	text-align: center;
	height: 515px;
	background: url(../img/top/main.gif) no-repeat center 30% #fff;
	background-size: cover;
	max-width: 1200px;
	margin: 0 auto;
	@media #{$mobile} {
		height: auto;
		padding-bottom: 58%;
	}
}
/* news */

.news {
	li {
		margin-bottom: 1em;
		overflow: hidden;
		div {
			display: flex;
			time {}
			div {
				padding-left: 1em;
				margin-left: 1em;
				border-left: 2px solid #5db785;
			}
		}
	}
	@media #{$mobile} {
		li {
			div {
				display: block;
				div {
					padding: 0;
					margin: 0;
					border-left: none; 
				}
				
			}
		}
	}
}
/* service */

.area_service {
	padding-top: 1em;
}

.list_service {
	li {
		background: $c-sub;
		padding: 4em 0;
		&:nth-child(2n) {
			background: $c-sub-dark;
		}
		&:last-child {
			padding-bottom: 6em;
		}
		dl {
			display: flex;
			align-items: center;
			dt {
				width: 360px;
				//				text-align: center;
			}
			dd {}
		}
	}
	@media #{$mobile} {
		li {
			padding: 2em 1em;
			dl {
				display: block;
				dt {
					width: 100%;
				}
			}
		}
	}
	
}
/* work */

.work {
	.box {
		margin: 140px 0;
		.tit_sub {
			margin-bottom: 2em;
		}
	}
	@media #{$mobile} {
		.box {
			margin: 70px 0;
		}
		
	}
}

.box_back_for {
	position: relative;
	overflow: hidden;
	&:before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 70px 0 70px 50px;
		border-color: transparent transparent transparent $c-yellow;
		position: absolute;
		left: 50%;
		top: 45%;
		margin-left: -25px;
	}
	dl {
		float: left;
		width: 390px;
		&:last-child {
			float: right;
		}
		dt {
			height: 3.6em;
		}
		&:last-child {
			dd {
				margin-top: 18%;
			}
		}
	}
	&.box {
		.tit_sub {
			margin-bottom: 1em;
		}
	}
	@media #{$mobile} {
		&:before {
			content: none;	
			position: relative;
		}
		dl {
			width: 100%;
			text-align: center;
			dt {
				height: auto;
			}
			&:last-child {
				dd {
					margin-top: auto;
				}
			}
			&:first-child {
				&:after {
					content: "";
					display: block;
					margin: 50px auto 10px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 30px 70px 30px 70px;
					border-color: $c-yellow transparent transparent transparent;

				}
			}
			
		}
		
		
	}
	
}




.box_graph {
	display: flex;
	justify-content: space-between;
	width: 100%;
	li {
		flex: 1;
		position: relative;
		overflow: hidden;
	}
	canvas {
		max-width: 320px;
		position: absolute;
		right: 0;
	}
	.year {
		&01 .circle_box {
			background: #45b5a1;
		}
		&02 .circle_box {
			background: #1c876e;
		}
		&03 .circle_box {
			background: #10cea2;
		}
	}
	@media #{$mobile} {
		display: block;
		li {
			margin: 0 0 20px;
		}
		canvas {
			max-width: 80%;
			right: 0;
		}
		img {
			width: 20%;
		}
	}
}

.chart_legend {
	font-size: 1.2rem;
	margin-top: 1em;
	ul {
		padding: 0 1em;
		text-align: center;
		li {
			display: inline-block;
			margin-right: 1em;
		}
	}
	span {
		width: 10px;
		height: 10px;
		display: inline-block;
		margin-right: 5px;
	}
	@media #{$mobile} {
		li {
			margin-bottom: 0;
		}
	}
}




.circle_box {
	position: relative;
	display: inline-block;
	margin-left: 10px;
	width: 50%;
	background: #ccc;
	border-radius: 50%;
	text-align: center;
	span {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
		transform : translateY(-50%);
	}
	@media #{$middle-mobile} {
		.tit_sub {
			font-size: 1.8rem;
		}
	}
	@media #{$mobile} {
		vertical-align: top;
		width: 40%;
		left: 15%;
	}
}

.text_list {
	max-width: 640px;
	font-size: 1.8rem;
	margin: 0 auto;
	li {
		margin-bottom: .5em;
		text-indent: -2em;
		padding-left: 2em;
		&:before {
			font-family:"FontAwesome";
			content:"\f046";
			padding-right: 1em;
			color: $c-yellow;
		}
	}
	@media #{$mobile} {
		font-size: 1.4rem;
	}
}



/* エンジニア募集 */

.to_recruit {
	.tit {
		margin-bottom: 3em;
	}
}

@charset "UTF-8";

//======= media query ========

$large-pc: "only screen and (min-width: 1024px)";
$pc: "only screen and (min-width: 768px)";
$middle-mobile: "only screen and (max-width: 980px)";
$mobile: "only screen and (max-width: 767px)";
$phone: "only screen and (max-width: 640px)";


@media #{$mobile} {
	
}
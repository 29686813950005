@charset "UTF-8";


//======= color ========

$c-main: #45b5a1;
$c-main-dark: #1f1f1f;
$c-sub: #389b84;
$c-sub-dark: #1c876e;
$c-yellow: #f0ff01;

.c_yellow {
	color: $c-yellow;
}

.gray {
	color: #3ead93;
}
